<template>
  <div>
    <ProjectDetailItemEditCard confirmation-message="Klant werd succesvol gewijzigd"
                               title="Bewerk gekoppelde klant"
                               :detail-view-route-location="{name: routeNames.PROJECT_DETAIL, params: {projectId: projectId, clientId: clientId}}"
                               :success-route-location="{name: routeNames.PROJECT_DETAIL, params: {projectId: projectId, clientId: autoFillClientId}}"
                               update-store-action="projectsModule/changeClient" :update-store-action-payload="{projectId: projectId, clientId: clientId, newClientId: autoFillClientId}"
                               :projectId="projectId" :clientId="clientId" :promises.sync="promises">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" md="6">
            <FetchOptionsSelect persistent-placeholder label="Klant" action="clientsModule/fetchNotArchivedClients" item-value="id" item-text="clientName"  v-model="autoFillClientId" noTranslate/>
          </v-col>
        </v-row>
      </template>
    </ProjectDetailItemEditCard>
  </div>
</template>

<script>
import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue";
import routeNames from "@/router/RouteNames";
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";

export default {
  name: "ProjectDetailsEditClient",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      autoFillClientId: this.clientId,
      routeNames: routeNames,
      promises: []
    }
  },
  components: {
    ProjectDetailItemEditCard,
    FetchOptionsSelect
  }
}
</script>